import { Link } from '@inertiajs/react'

export default function NavLink({ href, active, children, ...props }) {
    return (
        <Link
            href={href}
            className={`uppercase font-bold focus:bg-transparent focus:text-primary ${active ? 'text-primary' : ''}`}
            {...props}
        >
            {children}
        </Link>
    );
}
